// Import styles
import './main.scss';
import fullpage from 'fullpage.js';



//
// Application
//
const ProjectName = {
  // Options / globals
  init() {
    // On browser resize
    window.addEventListener('resize', () => this.onResize());
  },

  initOnLoad() {
  },

  onResize() {
    console.log('on resize');
  }
};

document.addEventListener('DOMContentLoaded', () => ProjectName.init());
window.addEventListener('load', () => ProjectName.initOnLoad());

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}

var bgvideo = document.getElementById("background__video");
  bgvideo.muted = true;
  bgvideo.play();

var myFullpage = new fullpage('#fullpage', {
    navigation: true,
    navigationTooltips: false,

    css3: true,
    scrollingSpeed: 1000,

    fitToSection: true,
	  fitToSectionDelay: 1300,

  	easing: 'easeInOutCubic',
  	easingcss3: 'ease',

  	scrollBar: false,
	  continuousVertical: true,

    lazyLoading: true,

    onLeave: function(origin, destination, direction){},
  	afterLoad: function(origin, destination, direction){},
  	afterRender: function(){},
  	afterResize: function(width, height){},
  	afterReBuild: function(){},
  	afterResponsive: function(isResponsive){},
  	afterSlideLoad: function(section, origin, destination, direction){},
  	onSlideLeave: function(section, origin, destination, direction){}
});


$(document).ready(function() {

  $(".NewsletterButton").click(function(){
    $(".Newsletter").addClass("is-visible");
  });

  $(".NewsletterClose").click(function(){
    $(".Newsletter").removeClass("is-visible");
  });

  $("nav li button").click(function(){
    $(".menu").toggleClass("is-visible");
    $("nav li button.black").toggleClass("white");
    $("nav li a.black").toggleClass("white");
  });

  $('.thumbnail').on('click', function () {
    $(this).closest('.wrapper__inner').find('.thumbnail').addClass('is-hidden');
    $(this).closest('.wrapper__inner').find('iframe').addClass('is-display');
  });

  $('.thumbnail').on('click', function() {
    $(this).closest('.wrapper__inner').find("iframe")[0].src += "&autoplay=1";
  });

  $("#close").click(function (){
      $("#close_p").text(($("#close_p").text() == 'Meny') ? 'Lukk' : 'Meny');
  })

  $(".accordion__item-content").click(function(){
    $(this).children(".accordion__item-text").toggleClass("is-visible");
    $(this).children('button').toggleClass("active");
  });

  $(".arrow-down")[0].addEventListener("click",fullpage_api.moveSectionDown)

});